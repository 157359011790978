
export const isAuthenticated = ()=>{
    const current = new Date();
   // const exDate = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    const edate = `${current.getDate()}`;
   // const month = `${current.getMonth()}`;
   // const year = `${current.getFullYear()}`;
    //const eDate = `${current.getDate()+2}/${current.getMonth()+1}/${current.getFullYear()}`;
    return edate?true:false;
}

export const passwd="Ratchu3@Soft";

export const bankName=[
    {
      "id":101,
      "label":"Bank Name",
      "name":"INDIAN BANK",
    }
];

export const bankDetails=[
    {
      "id":101,
      "label":"Account Name",
      "name":"SUDHAGAR. S",
    },
    {
        "id":102,
        "label":"Account No",
        "name":"6521320072",
      },
      {
        "id":103,
        "label":"IFSC Code",
        "name":"IDIB000T148",
      },
      {
        "id":104,
        "label":"MICR Code",
        "name":"623019006",
      },
      {
        "id":105,
        "label":"Account Type",
        "name":"SAVING",
      },
      {
        "id":106,
        "label":"Branch Address",
        "name":"THANGACHIMADAM",
      },
      {
        "id":107,
        "label":"Contact Number",
        "name":"+91 8148305945",
      },
      {
        "id":108,
        "label":"UBI(Gpay) Number",
        "name":"+91 8148305945",
      }
];

export const countryName=[
  {
    "id":101,
    "label":"Algeria",
    "currency":"SUDHAGAR. S",
  },
  {
      "id":102,
      "label":"Argentina",
      "currency":"6521320072",
    },
    {
      "id":103,
      "label":"Afghanistan",
      "currency":"IDIB000T148",
    },
    {
      "id":104,
      "label":"Australia",
      "currency":"623019006",
    },
    {
      "id":105,
      "label":"Azerbaijan",
      "currency":"SAVING",
    },
    {
      "id":106,
      "label":"Austria",
      "currency":"THANGACHIMADAM",
    },
    {
      "id":107,
      "label":"Brazil",
      "currency":"+91 8148305945",
    },
    {
      "id":108,
      "label":"Bangladesh",
      "currency":"+91 8148305945",
    },
    {
      "id":109,
      "label":"Belgium",
      "currency":"+91 8148305945",
    },
    {
      "id":110,
      "label":"Bulgaria",
      "currency":"+91 8148305945",
    },
    {
      "id":111,
      "label":"	Bahrain",
      "currency":"+91 8148305945",
    },
    {
      "id":112,
      "label":"	Bhutan",
      "currency":"+91 8148305945",
    },
    {
      "id":113,
      "label":"Barbados",
      "currency":"+91 8148305945",
    },
    {
      "id":114,
      "label":"China",
      "currency":"+91 8148305945",
    },
    {
      "id":115,
      "label":"Colombia",
      "currency":"+91 8148305945",
    },
    {
      "id":116,
      "label":"Canada",
      "currency":"+91 8148305945",
    },
    {
      "id":117,
      "label":"Cambodia",
      "currency":"+91 8148305945",
    },
    {
      "id":118,
      "label":"Cuba",
      "currency":"+91 8148305945",
    },
    {
      "id":119,
      "label":"Central African Republic",
      "currency":"+91 8148305945",
    },
    {
      "id":120,
      "label":"Comoros",
      "currency":"+91 8148305945",
    },
    {
      "id":121,
      "label":"Ethiopia",
      "currency":"+91 8148305945",
    },
    {
      "id":122,
      "label":"Egypt",
      "currency":"+91 8148305945",
    },
    {
      "id":123,
      "label":"France",
      "currency":"+91 8148305945",
    },
    {
      "id":124,
      "label":"Finland",
      "currency":"+91 8148305945",
    },
    {
      "id":125,
      "label":"Germany",
      "currency":"+91 8148305945",
    },
    {
      "id":126,
      "label":"Guinea",
      "currency":"+91 8148305945",
    },
    {
      "id":127,
      "label":"Greece",
      "currency":"+91 8148305945",
    },
    {
      "id":128,
      "label":"Hungary",
      "currency":"+91 8148305945",
    },
    {
      "id":129,
      "label":"India",
      "currency":"+91 8148305945",
    },
    {
      "id":130,
      "label":"Indonesia",
      "currency":"+91 8148305945",
    },
    {
      "id":131,
      "label":"Iran",
      "currency":"+91 8148305945",
    },
    {
      "id":132,
      "label":"Italy",
      "currency":"+91 8148305945",
    },
    {
      "id":133,
      "label":"Iraq",
      "currency":"+91 8148305945",
    },
    {
      "id":134,
      "label":"Israel",
      "currency":"+91 8148305945",
    },
    {
      "id":135,
      "label":"Ireland",
      "currency":"+91 8148305945",
    },
    {
      "id":136,
      "label":"Iceland",
      "currency":"+91 8148305945",
    },
    {
      "id":137,
      "label":"Japan",
      "currency":"+91 8148305945",
    },
    {
      "id":138,
      "label":"Jordan",
      "currency":"+91 8148305945",
    },
    {
      "id":139,
      "label":"Jamaica",
      "currency":"+91 8148305945",
    },
    {
      "id":140,
      "label":"Kenya",
      "currency":"+91 8148305945",
    },
    {
      "id":141,
      "label":"4Kazakhstan",
      "currency":"+91 8148305945",
    },
    {
      "id":142,
      "label":"Kuwait",
      "currency":"+91 8148305945",
    },
    {
      "id":143,
      "label":"Libya",
      "currency":"+91 8148305945",
    },
    {
      "id":144,
      "label":"Lebanon",
      "currency":"+91 8148305945",
    },
    {
      "id":145,
      "label":"Liberia",
      "currency":"+91 8148305945",
    },
    {
      "id":146,
      "label":"Mexico",
      "currency":"+91 8148305945",
    },
    {
      "id":147,
      "label":"Myanmar",
      "currency":"+91 8148305945",
    },
    {
      "id":148,
      "label":"Malaysia",
      "currency":"+91 8148305945",
    },
    {
      "id":149,
      "label":"Mali",
      "currency":"+91 8148305945",
    },
    {
      "id":150,
      "label":"Mongolia",
      "currency":"+91 8148305945",
    },
    {
      "id":151,
      "label":"Maldives",
      "currency":"+91 8148305945",
    },
    {
      "id":152,
      "label":"Nigeria",
      "currency":"+91 8148305945",
    },
    {
      "id":153,
      "label":"Nepal",
      "currency":"+91 8148305945",
    },
    {
      "id":154,
      "label":"North Korea",
      "currency":"+91 8148305945",
    },
    {
      "id":155,
      "label":"Netherlands",
      "currency":"+91 8148305945",
    },
    {
      "id":156,
      "label":"Norway",
      "currency":"+91 8148305945",
    },
    {
      "id":157,
      "label":"New Zealand",
      "currency":"+91 8148305945",
    },
    {
      "id":158,
      "label":"Namibia",
      "currency":"+91 8148305945",
    },
    {
      "id":159,
      "label":"Oman",
      "currency":"+91 8148305945",
    },
    {
      "id":160,
      "label":"Pakistan",
      "currency":"+91 8148305945",
    },
    {
      "id":161,
      "label":"Philippines",
      "currency":"+91 8148305945",
    },
    {
      "id":162,
      "label":"Poland",
      "currency":"+91 8148305945",
    },
    {
      "id":163,
      "label":"Peru",
      "currency":"+91 8148305945",
    },
    {
      "id":164,
      "label":"Papua New Guinea",
      "currency":"+91 8148305945",
    },
    {
      "id":165,
      "label":"Portugal",
      "currency":"+91 8148305945",
    },
    {
      "id":166,
      "label":"Paraguay",
      "currency":"+91 8148305945",
    },
    {
      "id":167,
      "label":"Qatar",
      "currency":"+91 8148305945",
    },
    {
      "id":168,
      "label":"Russia",
      "currency":"+91 8148305945",
    },
    {
      "id":169,
      "label":"Romania",
      "currency":"+91 8148305945",
    },
    {
      "id":170,
      "label":"Rwanda",
      "currency":"+91 8148305945",
    },
    {
      "id":171,
      "label":"South Africa",
      "currency":"+91 8148305945",
    },
    {
      "id":172,
      "label":"South Korea",
      "currency":"+91 8148305945",
    },
    {
      "id":173,
      "label":"Sudan",
      "currency":"+91 8148305945",
    },
    {
      "id":174,
      "label":"Spain",
      "currency":"+91 8148305945",
    },
    {
      "id":175,
      "label":"Saudi Arabia",
      "currency":"+91 8148305945",
    },
    {
      "id":176,
      "label":"Syria",
      "currency":"+91 8148305945",
    },
    {
      "id":177,
      "label":"Sri Lanka",
      "currency":"+91 8148305945",
    },
    {
      "id":178,
      "label":"Somalia",
      "currency":"+91 8148305945",
    },
    {
      "id":179,
      "label":"Senegal",
      "currency":"+91 8148305945",
    },
    {
      "id":180,
      "label":"South Sudan",
      "currency":"+91 8148305945",
    },
    {
      "id":181,
      "label":"Sweden",
      "currency":"+91 8148305945",
    },
    {
      "id":182,
      "label":"Switzerland",
      "currency":"+91 8148305945",
    },
    {
      "id":183,
      "label":"Serbia",
      "currency":"+91 8148305945",
    },
    {
      "id":184,
      "label":"Singapore",
      "currency":"+91 8148305945",
    },
    {
      "id":185,
      "label":"Solomon Islands",
      "currency":"+91 8148305945",
    },
    {
      "id":186,
      "label":"Turkey",
      "currency":"+91 8148305945",
    },
    {
      "id":187,
      "label":"Thailand",
      "currency":"+91 8148305945",
    },
    {
      "id":188,
      "label":"Tanzania",
      "currency":"+91 8148305945",
    },
    {
      "id":189,
      "label":"Tajikistan",
      "currency":"+91 8148305945",
    },
    {
      "id":190,
      "label":"United States of America",
      "currency":"+91 8148305945",
    },
    {
      "id":191,
      "label":"United Kingdom",
      "currency":"+91 8148305945",
    },
    {
      "id":192,
      "label":"Uganda",
      "currency":"+91 8148305945",
    },
    {
      "id":193,
      "label":"Ukraine",
      "currency":"+91 8148305945",
    },
    {
      "id":194,
      "label":"Uzbekistan",
      "currency":"+91 8148305945",
    },
    {
      "id":195,
      "label":"United Arab Emirates",
      "currency":"+91 8148305945",
    },
    {
      "id":196,
      "label":"Uruguay",
      "currency":"+91 8148305945",
    },
    {
      "id":197,
      "label":"Vietnam",
      "currency":"+91 8148305945",
    },
    {
      "id":198,
      "label":"Venezuela",
      "currency":"+91 8148305945",
    },
    {
      "id":199,
      "label":"Yemen",
      "currency":"+91 8148305945",
    },
    {
      "id":200,
      "label":"Zambia",
      "currency":"+91 8148305945",
    },
    {
      "id":201,
      "label":"Zimbabwe",
      "currency":"+91 8148305945",
    }
];