import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import App from './App';
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId='626598325272-871tt88t4mmmgvvk1a3r79t67cabio3d.apps.googleusercontent.com'>
    <React.StrictMode>
    <App />
    </React.StrictMode>
    </GoogleOAuthProvider>
);