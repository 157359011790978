import '../scss/navbar.scss';
import { Link } from 'react-router-dom';
import{FaUserPlus  , FaSignInAlt  } from 'react-icons/fa';

function NavBar() {

  return (
    <nav className='navBar'>
        <Link className="navLogo" data-no-turbolink="true" to="/" > <img src="./assets/logo.png" alt="Rameswaram BSS" className='logoImg' /></Link>
    <div className='topper'>
  <ul className='infoBox'>
  
    <li>
    <Link to={`/signin`}  className='infoText'>
        <FaSignInAlt className='infoIcon'/>
        <span id='lbl'> Log In</span>   
        </Link>
    </li>
    <li>    
       <Link to={`/signup`}  className='infoText'>
       <FaUserPlus className='infoIcon'/>
       <span id='lbl'> Sign Up</span>   
       </Link>
    </li>
  </ul>
</div>  
</nav>

  )
}

export default NavBar;