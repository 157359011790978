import '../scss/home.scss';
import styled from "styled-components";
import { imageZoomEffect, TitleStyles } from "../services/ReusableStyle";
import {  adPackages, myBlogs, webPackages } from '../services/impImages';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { TbWorld } from "react-icons/tb";
import { RiAdvertisementFill } from "react-icons/ri";
import { FaIdCard } from "react-icons/fa";
import { RiProfileFill } from "react-icons/ri";

function HomePage() {
  let navigate=useNavigate();
  const handleClick = (e) => navigate('/#');//toast("Currently Myvisapriority.com doesn't provide online visa application to " + e.target.name + ", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");

  const slides = [
    {id:1, url: 'assets/slider-main/bg01.png' },
    { id:2,url: 'assets/slider-main/bg02.png' },
    { id:3,url: 'assets/slider-main/bg03.png' },
    { id:4,url: 'assets/slider-main/bg04.png' },
    { id:5,url: 'assets/slider-main/bg05.png' },
    { id:5,url: 'assets/slider-main/bg06.png' }
  ];

  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  let settings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  
  return (
    <div className="body-inner" id="home">
    <section className="banner-carousel banner-carousel-1 mb-0">
      <ToastContainer style={{ width: '100%' }} />
      <div className='containerStyles'>
      <Slider {...mainSettings}>
      {slides.map((image) => (
                      <img loading="lazy" key={image.id} className='imgContainer' src={image.url} alt='Ratchu Software' />
              ))}
      </Slider>
      </div>
    </section>

    <section className="infoContainer">
          <div className="secIntro">
            <h2>Get started with our powerful web builder.</h2>
            <p>Powering 3 thousand businesses globally.</p>
<p>Ready for yours.</p>
          </div>
    </section>

    <Section id="facts" className="secFact" >
      <div className="container">
        <div className="row secFactsRow">
          <div className="col-md-3 col-sm-6">
            <div className="ts-facts-img secFactColumn">
            <TbWorld className='buttonIcon'/>
            <h3 className="secFactTitle">Create Your Website</h3>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="ts-facts-img secFactColumn">
            <RiAdvertisementFill  className='buttonIcon'/>
            <h3 className="secFactTitle">Create Your Ad</h3>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="ts-facts-img secFactColumn">
            <FaIdCard  className='buttonIcon'/>
            <h3 className="secFactTitle">Create Your Card</h3>
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="ts-facts-img secFactColumn">
            <RiProfileFill  className='buttonIcon'/>
            <h3 className="secFactTitle">Create Your Profile</h3>
            </div>
          </div>

        </div>
      </div>
    </Section>

    <section id="ts-features" className="infoContainer">
          <div className="secIntro">
            <h2>Build a free website that grows with your business.</h2>            
<p>Get access to customizable webpage designs and useful tools to build your website and grow your ideal business. Easily build a free website to help you get discovered and grow your customer base in style. Start today with our powerful free website builder.</p>
          </div>
    </section>


    <Section id="facts" className="infoContainer">
    <div className="container">
    <div className='blogItemBox'>
    <h2 className="quoteTitle">Build your website</h2>
    <div className="design">
        <div class="background"></div>
        </div>
    
    
    <Slider {...settings}>
        {webPackages.map((myBlog) => (
          <div className="blogBox" key={myBlog.id}>
            
             <Link to={'#'} style={{ textDecoration: "none" }}>
             <div className="portfolio__container">
             <h3 key={myBlog.id}>{myBlog.title}</h3>
        <p>{myBlog.txt}</p>
        
                <div className="image">
                <img src={myBlog.img} alt={myBlog.title} className='blogImage'/>
              </div>
             
                </div>
                </Link>
                
            </div>
           
        ))}
      </Slider>
      </div>
      <div className="design right">
             <div class="background"></div>
        </div>
      </div>
    </Section>

    <Section id="facts" className="infoContainer">
    <div className="container">
      <div className='blogItemBox'>
    <h2 className="quoteTitle">Build your success Ad</h2>
    <Slider {...settings}>
        {adPackages.map((ad) => (
           
            <div className="blogBox" key={ad.id}>
             <Link to={'#'} style={{ textDecoration: "none" }}>
            <img loading="lazy"  src={ad.img} alt={ad.title} className='blogImage' />
                <h5 className="blogTitle">{ad.title} </h5> 
                </Link>
            </div>
           
        ))}
      </Slider>
      </div>
      
      </div>
    </Section>

    <Section className="infoContainer">
      <div className="container">
            <div className="homeProdItemBox">
            <h2 className="quoteTitle">Variety of Models</h2>
            <div className='row'>
              {myBlogs.map((image) => (
                <div className="col-lg-4 col-md-6 mb-5" key={image.id}>
                   <Link to={'#'} style={{ textDecoration: "none" }}>
                  <Tooltip title={image.name.toUpperCase()} className="show">
                    <div className="prodItem">
                      <img loading="lazy" src={image.img} alt={image.name}  onClick={handleClick} />
                      <h5 className="blogTitle">{image.name} </h5>
                    </div>
                  </Tooltip>
                  </Link>
                </div>
              ))}
              </div>
            </div>
      </div>
    </Section>
    
  

    <section className="infoContainer" style={{ backgroundColor: 'rgb(230, 105, 3)' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="subscribe-call-to-acton">
              <h1 className='homeCallUs'> Grow your business, anytime, anywhere.</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    

  </div>
  )
}

export default HomePage;

const Section = styled.section`
  ${TitleStyles};
  ${imageZoomEffect};
  `;