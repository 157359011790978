import '../scss/signup.scss';
import {  useState,useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingBar from "react-top-loading-bar";
import { saveApi } from '../services/appApi';
import { passwd } from '../services/auth';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import { FaFacebookF } from "react-icons/fa";

function LoginPage() {

  const toastOptions = {
    autoClose: 700,
    pauseOnHover: true,
  }
  const ref = useRef(null);

  const form = useRef();
  const [data, setData] = useState("");

  const sendUser = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”
    ref.current.continuousStart();
    setData({ ...data,
      'contact_name': form.current.contact_name.value,
      'contact_email': form.current.contact_email.value,
      'my_email': 'info@rameswarambss.com',
      'contact_mobile_number': form.current.contact_mobile_number.value,
      'contact_location': form.current.contact_location.value,
      'contact_host':'mail.rameswarambss.com',
      'contact_message': form.current.contact_message.value,
      'contact_pwd': passwd,
      'user_id':'Ratchus' });

    console.log();
    saveApi(data,'mail').then((result) => {
      if (result) {
        toast(result.data.msg, toastOptions);
        ref.current.complete();
      } else {
        toast('Invalid Request', toastOptions);
        ref.current.complete();
      }
    });
  };


  const responseMessage = (response) => {
    console.log(response);
};
const errorMessage = (error) => {
    console.log(error);
};

const handleFacebookCallback = (response) => {
  if (response?.status === "unknown") {
      console.error('Sorry!', 'Something went wrong with facebook Login.');
   return;
  }
  console.log(response);
 }
  return (
     <div className="body-inner" id="contact">
       <LoadingBar color="#f11946" ref={ref} />
      
      <section id="main-container" className="infoContainer">
        <div className="container">
          
          <div id="contact_form" className='contextForm'>
          <div className='contactTitleBox'>
            <div className="row text-center">
              <div className="col-lg-12">
                <h2 className="contactTitle">Log In</h2>
              </div>
            </div>
          </div>
              <form ref={form} onSubmit={sendUser} accept-charset="UTF-8" id='signup_form'>
                    <div className="mb-3">
                      <label>Enter Your Email</label>
                      <input className="form-control" placeholder="Enter Your Email" type="mail" name="contact_email" id="contact_email" />
                    </div>
                    <div className="mb-3">
                      <label>Password</label>
                      <input className="form-control" placeholder="Password" required="required" type="password" name="password" id="password" />
                    </div>
                    
                    <div className="text-center mb-3"><br />
                      <input type="submit" name="commit" value="Submit" className="btn btn-primary solid blank" data-disable-with="Send" />
                    </div>
              </form>
              <div className="text-center mb-3">
                                  <hr/>
                                  </div>
                            <div className='btnGoogle'>
                            <GoogleLogin onSuccess={responseMessage} onError={errorMessage}
                             />
                    
                    </div>
                    <div className="text-center mb-3">
                                  <hr/>
                                  </div>
                    <div className='btnFacebook'>
                    <FacebookLogin 
                                          buttonStyle={{padding:"8px",borderRadius:"5px",backgroundColor:"white",color:"blue",fontSize:"12px"}}  
                                          appId="1817149305177673"  // we need to get this from facebook developer console by setting the app.
                                          autoLoad={false}  
                                          fields="name,email,picture"  
                                          callback={handleFacebookCallback}
                                          
                                        icon={<FaFacebookF />}
                                        textButton = "&nbsp;&nbsp;Sign In with Facebook"
                                          />
                    
                    </div>
          </div>
        </div>
      </section>
      <div className='infoContainer'></div>
    </div>
  )
}

export default LoginPage;