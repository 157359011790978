import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicePage from './pages/ServicePage';
import ContactPage from './pages/ContactPage';
import PrivacyPage from './pages/PrivacyPage';
import TermPage from './pages/TermPage';
import NavBar from './layouts/NavBar';
import NotFoundPage from './pages/NotFoundPage';
import FooterPage from './layouts/FooterPage';
import ProductPage from './pages/ProductPage';
import scrollreveal from "scrollreveal";
import PlacesPages from "./pages/PlacesPages";
import GalleryPage from "./pages/GalleryPage";
import BankPayment from "./pages/BankPayment";
import DetailsPage from "./pages/DetailsPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
//import SocialButton from "./components/SocialButton";

function App() {
 // const [menuOpen,setMenuOpen] = useState(false);
 useEffect(() => {
  const sr = scrollreveal({
    origin: "top",
    distance: "80px",
    duration: 1000,
    reset: false,
  });
  sr.reveal(
    `.topper,
    .navBar,
    #home,
    #facts,
    #about,
    #products,
    #services,
    #contact,
    #privacy,
    #termcondition,
    #notfound,
    .footer
  `,
    {
      opacity: 0,
      interval: 200,
    }
  );
}, []);

  return (
    <Router>
      <NavBar/>
    <Routes>
         <Route path="/">
         <Route index  element={<HomePage/>}/>
         <Route path="/signin"   element={<LoginPage/>}/>
         <Route path="/signup" element={<SignupPage/>}/>
          <Route path="products/:id" element={<DetailsPage title="packages" />}/>
          <Route path="otherplaces/:id" element={<DetailsPage  title="places" />}/>
          <Route path="places/:id" element={<DetailsPage title="rameswaramplaces" />}/>
          <Route path="/about" element={<AboutPage/>}/>

          <Route path="/products" element={<ProductPage/>} />
             
          <Route path="/places" element={<PlacesPages/>}/>
            
         
          <Route path="/services" element={<ServicePage/>}/>
         
          <Route path="/gallery" element={<GalleryPage/>}/>
       
          <Route path="/contact" element={<ContactPage/>}/> 
          <Route path="/privacy"  element={<PrivacyPage/>}/> 
          <Route path="/termcondition" element={<TermPage/>}/> 
          <Route path="/paymentoption" element={<BankPayment/>}/> 
          <Route  path="/notfound" element={<NotFoundPage/>}/> 
        </Route>
     </Routes>
    
     <FooterPage/>
  </Router>
  );
}

export default App;
